import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/scan-bags@2x.png'


const _hoisted_1 = {
  key: 2,
  class: "note fixed-footer-content fixed-footer-content--extra-padding"
}
const _hoisted_2 = { class: "note__body" }
const _hoisted_3 = { class: "note__header" }
const _hoisted_4 = {
  class: "note__title",
  "data-e2e": "labels-screen-title"
}
const _hoisted_5 = {
  class: "noteditDeliveryUnitPositione__subtitle mt-2",
  "data-e2e": "labels-screen-description"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  class: "scanned-delivery-unit__button",
  "data-e2e": "labels-screen-scan-button"
}
const _hoisted_8 = {
  key: 1,
  class: "scanned-delivery-unit__group-operations"
}
const _hoisted_9 = {
  key: 2,
  class: "scanned-delivery-unit"
}
const _hoisted_10 = {
  class: "scanned-delivery-unit__list",
  "data-e2e": "scanned-delivery-unit-list"
}
const _hoisted_11 = {
  key: 0,
  class: "scanned-delivery-unit-manual__item scanned-delivery-unit__item"
}
const _hoisted_12 = { "data-e2e": "scanned-delivery-unit-count" }
const _hoisted_13 = { "data-e2e": "scanned-delivery-unit-position" }
const _hoisted_14 = { key: 3 }
const _hoisted_15 = {
  class: "scanned-delivery-unit__text",
  "data-e2e": "scanned-delivery-unit-text"
}
const _hoisted_16 = {
  key: 3,
  class: "footer fixed-footer-content__footer"
}
const _hoisted_17 = { class: "ut-font_fix" }

import { $t } from '@/i18n';
import type { Ref } from 'vue';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { vueBarcodePlugin } from '@/features/barcode';
import type { DeliveryUnit } from '@/features/delivery-unit';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import {
  AppHeader,
  BackButton,
  BarcodeScannerIcon,
  Button,
  ScreenLoading,
} from '@/features/ui';
import type { ScannableDeliveryUnit } from '@/features/review';
import {
  filterScannedDeliveryUnits,
  groupScannedDeliveryUnitsByPosition,
  resetDeliveryUnitPositionsAndFocusFirst,
  scannableDeliveryUnitsComparator,
} from '@/features/review';
import {
  useOrder,
  useOrderRestaging,
  useOrderPickingCompletion,
} from '@/features/review/composables';
import {
  deviceFeedbackServicePlugin,
  playlistActionRequiredBarcodeScan,
  playlistSuccessBarcodeScan,
} from '@/features/device-feedback';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';
import { useOrder as usePickingOrder } from '@/features/picking';
import { LABEL_FOR_MANUALLY_SCAN } from '@/features/bags/types';
import { DeliveryUnitBlock } from '../components';
import BagsManualScanning from '../components/BagsManualScanning.vue';
import { deepClone } from '@/utils/helpers/deepClone';
import { isArrayOfObjectsEqualBy } from '@/utils/helpers/isArrayOfObjectsEqualBy';


export default /*@__PURE__*/_defineComponent({
  __name: 'ScanBagPosition',
  props: {
  id: { type: String, required: true },
},
  setup(__props) {

const props = __props;

const { order, loadOrder, loading } = useOrder();
const { redirectIfOrderNotPicking } = usePickingOrder();
const { startTracking, stopTracking } = usePerformanceTracker();
const { completePicking } = useOrderPickingCompletion();
const {
  restage,
  deleteLatestRestagingStartedTrackedEvent,
  goHandoverCustomerInformation,
} = useOrderRestaging();

const deliveryUnits = ref<ScannableDeliveryUnit[]>([]);
let originalDeliveryUnits: ScannableDeliveryUnit[] = [];

const route = useRoute();
const isRestagingPage: boolean = route.name === 'restaging';
const isPickingBagPositionPage: boolean = route.name === 'picking-bag-position';

const title = isRestagingPage
  ? $t('pages.scan-bag-position.title.restaging')
  : $t('pages.scan-bag-position.title.picking');
const subtitle = isRestagingPage
  ? $t('pages.scan-bag-position.subtitle.restaging')
  : $t('pages.scan-bag-position.subtitle.picking');

onMounted(async () => {
  await loadOrder(props.id);
  stopTracking(`customer-note-to-bags-position-${props.id}`);

  if (!isRestagingPage) {
    await redirectIfOrderNotPicking(order.value);
  }

  deliveryUnits.value =
    order.value?.deliveryUnits.map((unit) => ({
      ...unit,
      scanned: isRestagingPage,
    })) || [];
  originalDeliveryUnits = deepClone(deliveryUnits.value);

  stopTracking(
    isRestagingPage
      ? `customer-information-to-restaging-${props.id}`
      : `customer-note-to-bags-position-${props.id}`,
  );
});

const disableWhileSave = ref(false);
const goNext = async () => {
  startTracking(
    'bags-position-to-order-list',
    PerformanceThresholdEnum.ROUTE_CHANGE,
  );
  disableWhileSave.value = true;
  if (order.value && order.value.deliveryUnits) {
    deliveryUnits.value.forEach(function (item) {
      if (!item.position) item.position = LABEL_FOR_MANUALLY_SCAN;
      delete item.scanned;
    });
    order.value.deliveryUnits = deliveryUnits.value;
  }

  if (isPickingBagPositionPage) {
    await completePicking(order);
  }

  if (isRestagingPage) {
    await restage(order);
  }

  disableWhileSave.value = false;
};

const scannedDeliveryUnits = computed<ScannableDeliveryUnit[]>(() =>
  filterScannedDeliveryUnits(deliveryUnits.value),
);

const amountOfScannedBags = computed(() => {
  return (
    scannedDeliveryUnits.value.filter((unit) => unit.position).length +
    manualAddedBags.value
  );
});

const amountOfUnScannedBags = computed(() => {
  return deliveryUnits.value.length - scannedDeliveryUnits.value.length;
});

const disabledConfirmButton = computed(() => {
  return amountOfScannedBags.value < deliveryUnits.value.length;
});

const showScannedDeliveryUnitButton = computed(() => {
  if (isRestagingPage) {
    return true;
  }

  const numberScannedDeliveryUnitWithPosition =
    scannedDeliveryUnits.value.filter((unit) => unit.position).length;
  const numberScannedDeliveryUnit = scannedDeliveryUnits.value.length;
  return (
    !numberScannedDeliveryUnit ||
    (numberScannedDeliveryUnit === numberScannedDeliveryUnitWithPosition &&
      disabledConfirmButton)
  );
});

const scannedDeliveryUnitsGroupedByPosition = computed(() =>
  groupScannedDeliveryUnitsByPosition(scannedDeliveryUnits.value),
);

const isEditingPosition = ref(false);
const changeDeliveryUnitPosition = (
  deliveryUnit: DeliveryUnit,
  position: string,
) => {
  for (const unit of scannedDeliveryUnits.value) {
    if (deliveryUnit.position === unit.position) {
      unit.position = position.trim();
    }
  }
  isEditingPosition.value = false;
};

const openDeliveryUnitPosition = () => {
  isEditingPosition.value = true;
};

const barcodeService = vueBarcodePlugin.get();
barcodeService.onBarcode({
  async next(bagQrCode: string) {
    const bagReference: string = bagQrCode.split(':')[1] || '';

    const currentDeliveryUnit = deliveryUnits.value.find(
      (unit) => unit?.reference === bagReference,
    );

    if (!currentDeliveryUnit) return;

    if (currentDeliveryUnit.scanned) {
      notificationPlugin.get().show({
        text: $t('errors.barcode.already-scanned.message'),
        type: NotificationType.Error,
      });
      await deviceFeedbackServicePlugin
        .get()
        .trigger(playlistActionRequiredBarcodeScan);
      return;
    }

    currentDeliveryUnit.scanned = true;
    if (
      scannedDeliveryUnits.value.length + manualAddedBags.value >
      deliveryUnits.value.length
    ) {
      manualAddedBags.value--;
    }
    await deviceFeedbackServicePlugin.get().trigger(playlistSuccessBarcodeScan);
  },
  pattern: 'BagsScanCollectionRegexp',
});

barcodeService.onBarcode({
  async next(bagQrCode: string) {
    const numberScannedDeliveryUnitWithoutPosition =
      scannedDeliveryUnits.value.filter((unit) => !unit.position).length;
    if (
      !scannedDeliveryUnits.value.length ||
      !numberScannedDeliveryUnitWithoutPosition
    ) {
      notificationPlugin.get().show({
        text: $t('pages.scan-bag-position.scan-error.text'),
        type: NotificationType.Error,
      });

      await deviceFeedbackServicePlugin
        .get()
        .trigger(playlistActionRequiredBarcodeScan);

      return;
    }

    for (const unit of scannedDeliveryUnits.value) {
      if (!unit.position) {
        unit.position = bagQrCode;
        await deviceFeedbackServicePlugin
          .get()
          .trigger(playlistSuccessBarcodeScan);
      }
    }
  },
  pattern: 'ShelfPositionRegexp',
});

const manualAddedBags = ref(0);
const triggerManualSaving = (count: number) => {
  manualAddedBags.value = count;
};

const restoreOriginal = () => {
  deliveryUnits.value = deepClone(originalDeliveryUnits);
};

const deliveryUnitBlockRefs: Ref<Record<string, typeof DeliveryUnitBlock>> =
  ref({});

const setDeliveryUnitBlockRefs = (
  element: typeof DeliveryUnitBlock,
  index: number,
) => {
  deliveryUnitBlockRefs.value[`deliveryUnitBlock-${index}`] = element;
};

const changeAll = async () => {
  await resetDeliveryUnitPositionsAndFocusFirst(
    deliveryUnits,
    deliveryUnitBlockRefs,
  );
};

const isRestoreOriginalButtonVisible = computed<boolean>(
  () =>
    isRestagingPage &&
    !isArrayOfObjectsEqualBy(
      deliveryUnits.value,
      originalDeliveryUnits,
      scannableDeliveryUnitsComparator,
    ),
);
const isChangeAllButtonVisible = computed<boolean>(
  () =>
    isRestagingPage && scannedDeliveryUnitsGroupedByPosition.value.length > 1,
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(order))
      ? (_openBlock(), _createBlock(_unref(AppHeader), {
          key: 0,
          class: "header"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(BackButton), {
              "data-e2e": "back-button",
              to: {
        name: isRestagingPage
          ? 'handover-customer-information'
          : 'complete-picking-order',
        params: { id: __props.id },
      },
              beforeNavigate: 
        isRestagingPage
          ? () => _unref(deleteLatestRestagingStartedTrackedEvent)(_unref(order))
          : null
      
            }, null, 8, ["to", "beforeNavigate"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(loading))
      ? (_openBlock(), _createBlock(_unref(ScreenLoading), {
          key: 1,
          title: _unref($t)('components.review-assigning-storage-zones.loading-title'),
          blur: ""
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    (_unref(order))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "note__image" }, [
                _createElementVNode("img", {
                  alt: "",
                  class: "ut-responsive-img",
                  "data-e2e": "scan-bags-screen-image",
                  src: _imports_0
                })
              ], -1)),
              _createElementVNode("h1", _hoisted_4, _toDisplayString(_unref(title)), 1),
              _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(subtitle)), 1)
            ]),
            isPickingBagPositionPage
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, [
                    _createVNode(_unref(BarcodeScannerIcon), {
                      height: "25",
                      width: "25"
                    }),
                    _createTextVNode(" " + _toDisplayString(scannedDeliveryUnits.value.length === deliveryUnits.value.length
              ? _unref($t)('pages.scan-bag-position.scan-bag-last.text')
              : _unref($t)('pages.scan-bag-position.scan-bag.text')), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            isRestagingPage
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", null, [
                    (isRestoreOriginalButtonVisible.value)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          onClick: restoreOriginal,
                          class: "scanned-delivery-unit__group-operations__restore-original-button",
                          "data-e2e": "restore-original-button"
                        }, _toDisplayString(_unref($t)('pages.scan-bag-position.restore-original-button.text')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", null, [
                    (isChangeAllButtonVisible.value)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          onClick: changeAll,
                          class: "scanned-delivery-unit__group-operations__change-all-button",
                          "data-e2e": "change-all-button"
                        }, _toDisplayString(_unref($t)('pages.scan-bag-position.change-all-button.text')), 1))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            (scannedDeliveryUnits.value.length || manualAddedBags.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    (manualAddedBags.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("strong", null, _toDisplayString(manualAddedBags.value), 1),
                            _createTextVNode(" " + _toDisplayString(_unref($t)('pages.scan-bag-position.bags.text')), 1)
                          ]),
                          _createElementVNode("div", _hoisted_13, _toDisplayString(_unref($t)('pages.scan-bag-position.placed-without-scan.text')), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scannedDeliveryUnitsGroupedByPosition.value, (deliveryUnit, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: deliveryUnit.position,
                        class: "scanned-delivery-unit__item"
                      }, [
                        _createVNode(_unref(DeliveryUnitBlock), {
                          ref_for: true,
                          ref: (el) => setDeliveryUnitBlockRefs(el, index),
                          "delivery-unit": deliveryUnit,
                          onEditDeliveryUnitPosition: changeDeliveryUnitPosition,
                          onOpenEditingDeliveryUnitPosition: openDeliveryUnitPosition
                        }, null, 8, ["delivery-unit"])
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true),
            (!showScannedDeliveryUnitButton.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("p", _hoisted_15, _toDisplayString(_unref($t)('pages.scan-bag-position.scan-to-continue.text')), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          isPickingBagPositionPage
            ? (_openBlock(), _createBlock(BagsManualScanning, {
                key: 0,
                "delivery-manual-count": manualAddedBags.value,
                "max-delivery-unit-count": amountOfUnScannedBags.value,
                onApply: triggerManualSaving
              }, null, 8, ["delivery-manual-count", "max-delivery-unit-count"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_unref(order))
      ? (_openBlock(), _createElementBlock("footer", _hoisted_16, [
          _createVNode(_unref(Button), {
            disabled: disabledConfirmButton.value || disableWhileSave.value || isEditingPosition.value,
            btnOutline: "",
            class: "footer__button column--full",
            "data-e2e": "scan-bag-position-confirm-button",
            onClick: goNext
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_17, _toDisplayString(_unref($t)('pages.scan-bag-position.confirm-button.text')) + " (" + _toDisplayString(scannedDeliveryUnits.value.length + manualAddedBags.value) + "/" + _toDisplayString(deliveryUnits.value.length) + ") ", 1)
            ]),
            _: 1
          }, 8, ["disabled"]),
          isRestagingPage
            ? (_openBlock(), _createBlock(_unref(Button), {
                key: 0,
                class: "footer__button column--full",
                btnOutline: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(goHandoverCustomerInformation)(_unref(order)))),
                "data-e2e": "stop-restaging-button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref($t)('pages.scan-bag-position.stop-restaging-button.text')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})